@import "normalize.css";
@import "main.css";
html {
  font-family: roboto, helvetica, sans-serif;
  font-size: 14px;
  line-height: 1.33333em;
  color: #4a4a4a;
  text-align: center;
  scroll-behavior: smooth;
}
h1 {
  font-size: 2.57142em;
  line-height: 1.33em;
  font-weight: 900;
  margin: 0;
  padding-top: 1em;
}
h1:after {
  content: "";
  background-image: url("../img/ps-slug.svg");
  background-repeat: no-repeat;
  background-size: contain;
  width: 0.828125em;
  height: 1.1em;
  position: absolute;
  top: 1.1em;
  left: 50%;
  margin-left: -1.25em;
}
h2 {
  font-size: 2.142857em;
  line-height: 1.1875em;
  font-weight: 900;
  margin: 0;
  padding-top: 1.7em;
  position: relative;
}
h2:after {
  content: "";
  background-image: url("../img/ps-slug.svg");
  background-repeat: no-repeat;
  background-size: contain;
  width: 0.828125em;
  height: 1.1em;
  position: absolute;
  top: 1em;
  left: 50%;
  margin-left: -1.25em;
}
sup {
  font-weight: 300;
  font-size: 0.5em;
}
cite {
  font-size: 0.6em;
  font-weight: 300;
  display: block;
  margin: 1.5em 0 0;
  max-width: 50%;
  line-height: 1.4em;
}
.sub-title {
  font-weight: 300 !important;
  font-size: 1.22222em;
  line-height: 1.16667em;
  margin: 0 auto;
}
.point {
  text-transform: uppercase;
  font-size: 1.1111em;
  font-weight: 700;
}
.badge-list {
  display: flex;
  flex-direction: column;
  margin: 0.5em auto 2.5em;
  padding: 0;
  list-style-type: none;
  width: 96vw;
  align-items: flex-start;
  justify-content: space-around;
}
.badge-list li {
  font-size: 1em;
  line-height: 1.142857em;
  font-weight: 700;
  background-image: url("../img/line-key.svg");
  background-position: left center;
  background-repeat: no-repeat;
  padding-left: 6em;
  background-size: auto 6em;
  height: 6em;
  display: flex;
  align-items: center;
  text-align: left;
}
.badge-list li a,
.badge-list li a:link,
.badge-list li a:visited {
  color: #4a4a4a;
  text-decoration: none;
  font-weight: 300;
  font-style: italic;
  display: block;
  border-radius: 5px;
  padding: 0.5em;
}
.badge-list li a:hover,
.badge-list li a:link:hover,
.badge-list li a:visited:hover {
  box-shadow: 2px 2px 8px 0 rgba(85, 85, 85, 0.5);
}
.badge-list li.book {
  padding-top: 0;
  margin-bottom: 1em;
  background-position: 1em center;
}
.badge-list li.hidden-talent {
  background-image: url("../img/line-key.svg");
}
.badge-list li.retention {
  background-image: url("../img/line-retention.svg");
}
.badge-list li.empower {
  background-image: url("../img/line-finishline.svg");
}
.badge-list li.maximize {
  background-image: url("../img/line-arrowsup.svg");
}
.badge-list li.equip {
  background-image: url("../img/line-group.svg");
}
.badge-list li.no-more-talent-flight {
  background-image: url("../img/book-no-more-talent-flight.jpg");
}
.badge-list li.successful-together {
  background-image: url("../img/book-successful-together.jpg");
}
.badge-list li.six-steps {
  background-image: url("../img/book-six-steps.jpg");
}
.badge-list li.improved-retention {
  background-image: url("../img/book-improved-retention.jpg");
}
.ornate {
  background-color: #f4f4f2;
  background-image: url("../img/topo.svg");
}
header {
  height: 8vw;
  background-color: #4a4a4a;
  overflow: visible;
}
header img {
  width: 21em;
  height: auto;
  max-width: 30vw;
  position: absolute;
  top: 1em;
  left: 2em;
}
header nav {
  display: none;
}
header .get-started {
  position: fixed;
  bottom: -5em;
  width: 100vw;
  left: 0;
  z-index: 10;
}
header .get-started.active ul {
  top: -7.7em;
}
header .get-started h2 {
  background-color: #CE5E2C;
  box-shadow: 2px 2px 8px 0 rgba(85, 85, 85, 0.5);
  font-size: 1em;
  color: white;
  text-transform: uppercase;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  height: 2.8em;
  padding: 0 3em;
  margin: 0;
  z-index: 999;
  position: relative;
  cursor: pointer;
}
header .get-started h2:after {
  display: none;
}
header .get-started ul {
  margin: 0;
  padding: 1em;
  box-shadow: 2px 2px 8px 0 rgba(85, 85, 85, 0.5);
  background-color: #ED7742;
  color: white;
  list-style-type: none;
  text-align: left;
  position: relative;
  top: -2em;
  z-index: 998;
  transition: top 0.25s ease-in;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
header .get-started ul li:first-child a,
header .get-started ul li:first-child a:link,
header .get-started ul li:first-child a:visited {
  border-right: solid rgba(255, 255, 255, 0.5) 1px;
  margin-right: 1.5em;
  padding-right: 1.5em;
}
header .get-started ul li a,
header .get-started ul li a:link,
header .get-started ul li a:visited {
  color: white;
  text-decoration: none;
  font-family: "Roboto Slab", serif;
  background-image: url("../img/orange-play.svg");
  background-position: left center;
  background-repeat: no-repeat;
  padding-left: 3em;
  min-height: 3em;
  display: flex;
  justify-content: left;
  align-content: center;
  align-items: center;
}
header .get-started ul li a:hover,
header .get-started ul li a:link:hover,
header .get-started ul li a:visited:hover {
  text-decoration: underline;
}
header .get-started ul li a.demo,
header .get-started ul li a:link.demo,
header .get-started ul li a:visited.demo {
  background-image: url("../img/orange-conversation.svg");
}
.tabs {
  margin-top: 3em;
}
.tabs nav {
  display: none;
}
.tabs .tab-container {
  position: relative;
}
.tabs .tab-container .tab-accordion-header {
  display: block;
  line-height: 3.33333em;
  text-align: center;
  color: #4a4a4a;
  text-decoration: none;
  position: relative;
  cursor: pointer;
  background-color: #97C543;
  border-bottom: solid white 1px;
}
.tabs .tab-container .tab-accordion-header h3 {
  margin: 0;
}
.tabs .tab-container .tab-accordion-header.active h3 {
  color: white;
}
.tabs .tab-container .tab {
  text-align: left;
  width: 96vw;
  margin: auto;
  position: relative;
}
.tabs .tab-container .tab:after {
  content: "";
  background-image: url("../img/screen-map.jpg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: bottom right;
  max-height: 11em;
  height: 40vw;
  width: 32.5vw;
  position: absolute;
  bottom: 0;
  right: -0.5em;
  z-index: 1;
}
.tabs .tab-container .tab.design:after {
  background-image: url("../img/ipad-dashboard-framed.png");
}
.tabs .tab-container .tab.mapping:after {
  background-image: url("../img/ipad-jobmap-framed.png");
}
.tabs .tab-container .tab.matching:after {
  background-image: url("../img/ipad-jobmatch-framed.png");
}
.tabs .tab-container .tab.action-plans:after {
  background-image: url("../img/ipad-actionplan-framed.png");
}
.tabs .tab-container .tab.modeling:after {
  background-image: url("../img/ipad-jobsblueprint-framed.png");
}
.tabs .tab-container .tab.insights:after {
  background-image: url("../img/ipad-companyreport-framed.png");
}
.tabs .tab-container .tab:first-child {
  display: block;
}
.tabs .tab-container .tab h3 {
  display: none;
}
.tabs .tab-container .tab p {
  font-weight: 300;
}
.tabs .tab-container .tab .the-results {
  text-align: left;
  padding-top: 1em;
  margin-top: 1.5em;
  margin-bottom: 1em;
  border-top: dashed 1px #97C543;
}
.tabs .tab-container .tab .the-results h4 {
  font-size: 0.66667em;
  font-style: italic;
  font-weight: 500;
  text-transform: uppercase;
  margin: 0;
}
.tabs .tab-container .tab .the-results .stat {
  font-size: 3.333333em;
  line-height: 1em;
  color: #97C543;
  font-weight: 900;
  margin: 0;
}
.tabs .tab-container .tab .the-results .unit {
  font-weight: 700;
  line-height: 1.166667em;
  margin: 0;
  text-transform: uppercase;
}
.tabs .tab-container .tab .the-results .detail {
  font-size: 0.777777em;
  line-height: 1.1428571em;
  margin: 0;
  margin-top: 0.5em;
  font-style: italic;
  max-width: 65vw;
}
section {
  padding-bottom: 1em;
}
section.hero {
  background-color: #fbfbfb;
  background-image: url("../img/hero.jpg");
  background-size: 170vw auto;
  background-position: bottom center;
  background-repeat: no-repeat;
  height: 120vw;
  min-height: 24em;
  padding-bottom: 0;
}
section.hero h1 {
  position: relative;
  padding-top: 1.7em;
}
section.hero .sub-title {
  margin: 0 auto;
}
section.hero p {
  font-size: 1em;
  line-height: 1.2em;
  font-weight: 500;
  max-width: 90vw;
  margin: 1.5em auto;
}
section > p {
  max-width: 96vw;
  margin: 1.5em auto;
}
section.success h2:after {
  margin-left: -1.67em;
}
section.success h3 {
  text-transform: uppercase;
  font-size: 1em;
  line-height: 1.16667em;
  padding: 0.55555em 1em;
  background-color: #97C543;
  margin: auto;
  display: inline-block;
  color: white;
}
section.results {
  position: relative;
}
section.results h2:after {
  margin-left: -1.48em;
}
section.contact h2:after {
  margin-left: -0.04em;
}
section.contact p {
  font-size: 1.33333em;
}
section.contact .multi-column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: stretch;
  align-items: stretch;
}
section.contact .video {
  display: block;
  border-radius: 5px;
  max-width: 14em;
  flex-grow: 1;
  flex-shrink: 1;
  color: #4a4a4a;
  text-decoration: none;
  padding: 2.5em 0.5em 0.5em;
  margin: 3em auto 1em;
  position: relative;
}
section.contact .video.ornate {
  background-color: rgba(151, 197, 67, 0.15);
  background-image: none;
}
section.contact .video:after {
  content: "";
  background-image: url("../img/green-play.svg");
  background-size: contain;
  height: 4.1666666em;
  width: 4.1666666em;
  position: absolute;
  top: -2.08333333em;
  left: 50%;
  margin-left: -2.0833333em;
}
section.contact .video h3 {
  font-size: 1em;
  font-weight: 700;
  line-height: 1.1666667em;
  margin: 0.25em;
}
section.contact .video p {
  font-size: 0.88888em;
  line-height: 1.1875em;
  font-weight: 300;
}
section.contact form {
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 2em;
  grid-row-gap: 0.5em;
  width: 96vw;
  margin: auto;
}
section.contact label {
  position: absolute;
  top: -1000em;
}
section.contact input[type='email'] {
  border-radius: 5px;
  background-color: #F4F4F2;
  box-shadow: inset 1px 1px 4px 0 rgba(166, 166, 166, 0.5);
  padding: 1.33333em;
  color: #565758;
  border-width: 0;
}
section.contact input[type='email']:focus {
  box-shadow: inset 1px 1px 4px 0 rgba(166, 166, 166, 0.5), 0px 0px 5px 2px rgba(151, 197, 67, 0.6);
}
section.contact input[type='button'],
section.contact input[type='submit'] {
  border-radius: 5px;
  background-color: #97C543;
  padding: 1.3333em;
  color: white;
  border-width: 0;
  text-transform: uppercase;
  text-align: center;
  cursor: pointer;
}
section.support {
  padding: 0 5vw 4em;
}
section.support h2:after {
  margin-left: -1.6em;
}
section.support .spaced {
  margin: 2em auto;
  font-size: 1.33333em;
}
section.support h3 {
  font-size: 1.11111em;
  line-height: 1.2em;
  color: #97C543;
  text-transform: uppercase;
  text-align: left;
  margin: 3em 0 2em;
}
section.support .support-tool {
  text-align: left;
  padding-left: 5em;
  margin-bottom: 2em;
  background-image: url("../img/line-slots.svg");
  background-position: top left;
  background-repeat: no-repeat;
  min-height: 4em;
}
section.support .support-tool.alignment {
  background-image: url("../img/line-slots.svg");
}
section.support .support-tool.framework {
  background-image: url("../img/line-pencil.svg");
}
section.support .support-tool.blueprint {
  background-image: url("../img/line-check.svg");
}
section.support .support-tool.strategy {
  background-image: url("../img/line-arrowsout.svg");
}
section.support .support-tool.itf {
  background-image: url("../img/line-circlearrowsin.svg");
}
section.support .support-tool h4 {
  font-size: 1em;
  font-weight: 700;
  margin: 0;
}
section.support .support-tool p {
  font-size: 0.8888888em;
  line-height: 1.5em;
  font-weight: 300;
  margin: 0;
}
section.everybody {
  padding-top: 2em;
  padding-bottom: 4em;
}
section.everybody h2:after {
  margin-left: -2.1em;
}
section.alliance h2 {
  text-transform: uppercase;
  font-size: 1em;
  line-height: 1.16667em;
  padding: 0.55555em 3em;
  background-color: #97C543;
  margin: auto;
  margin-top: 0;
  display: inline-block;
  color: white;
  position: relative;
  top: -1em;
}
section.alliance h2:after {
  display: none;
}
section.alliance h4 {
  font-size: 0.77777em;
  line-height: 1.1428571em;
  font-weight: 500;
  font-style: italic;
  text-transform: uppercase;
  margin-top: 3em;
  margin-bottom: 2em;
}
section.alliance .logo-cael {
  height: 3.5em;
  padding-right: 1em;
}
section.alliance .logo-sh {
  height: 4.5em;
  padding-left: 1em;
}
footer {
  background-color: #4a4a4a;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2em 2em 4em;
}
footer nav {
  width: 100%;
  padding: 0 0 2em;
  margin: 0 2em;
  border-bottom: solid 1px #97c543;
}
footer nav a,
footer nav a:link,
footer nav a:visited {
  color: white;
  padding: 0 1em;
  border-left: solid white 1px;
  text-decoration: none;
}
footer nav a:hover,
footer nav a:link:hover,
footer nav a:visited:hover {
  text-decoration: none;
}
footer nav a:first-child,
footer nav a:link:first-child,
footer nav a:visited:first-child {
  border: none;
}
footer h3 {
  font-size: 1.11111em;
  line-height: 1.2em;
  color: #97C543;
  text-transform: uppercase;
  text-align: center;
  margin: 0;
}
footer p {
  color: white;
  font-weight: 300;
}
footer > div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 2em;
}
footer > div img {
  width: 184px;
  max-height: 124px;
  height: auto;
  margin: 2em;
}
footer > div a,
footer > div a:link,
footer > div a:visited {
  color: white;
  text-decoration: underline;
  text-transform: uppercase;
}
footer > div a:hover,
footer > div a:link:hover,
footer > div a:visited:hover {
  text-decoration: none;
}
footer > div > div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 20em;
}
.sources {
  list-style-type: none;
  margin: 1em 0;
  padding: 0 15vw;
  font-size: 0.666667em;
  line-height: 1.166667em;
  font-weight: 300;
  text-align: left;
}
#overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #4a4a4a;
  opacity: 0.95;
  overflow-y: scroll;
  z-index: 2;
  font-size: 0.88888889em;
}
#overlay #overlayClose {
  margin: 2em;
  position: fixed;
  right: 0;
  cursor: pointer;
}
#overlay #overlayClose img {
  height: auto;
  max-width: 23px;
  width: 100%;
}
#overlay #overlayClose p {
  font-size: 1.25em;
  color: white;
  margin: 0.5em;
}
#overlay h1::after,
#optionWrap h1::after,
#overlay h2::after,
#optionWrap h2::after {
  content: none;
}
#overlay h1,
#optionWrap h1 {
  color: white;
  font-weight: 900;
  font-size: 7.125em;
  padding-top: 0.75em;
}
#overlay h2,
#optionWrap h2 {
  color: white;
  font-weight: 700;
  font-size: 1.875em;
  padding-top: 0;
}
#overlay p,
#optionWrap p {
  color: #97c543;
  font-weight: 700;
  font-size: 1.25em;
  text-align: left;
}
#optionWrap {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  align-content: flex-start;
  justify-content: center;
  margin-top: 5em;
}
#optionWrap .option {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 2em;
  margin-bottom: 4em;
}
#optionWrap .option:first-child {
  padding-right: 3.75em;
  border-right: solid 1px #97c543;
}
#optionWrap .option:last-child {
  padding-left: 3.75em;
}
#optionWrap .option > div {
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  margin-top: 2em;
}
#optionWrap .option > div > img {
  margin: -1em 1em 0 -1em;
}
#optionWrap .option > div > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
}
#optionWrap .option > div > div img {
  width: auto;
  max-height: 124px;
  height: 100%;
}
#optionWrap .option > div > div ul {
  margin: 2em 0 4em;
}
#optionWrap .option > div > div ul > li {
  color: white;
  text-align: left;
  font-size: 1.125em;
  font-weight: 300;
  width: 16.75em;
}
#optionWrap .option > div > div a.button {
  max-width: 16.75em;
  width: 100%;
}
#optionWrap a.button {
  border-radius: 5px;
  background-color: #97C543;
  padding: 1.3333em;
  color: white;
  border-width: 0;
  text-transform: uppercase;
  text-align: center;
  cursor: pointer;
  text-decoration: none;
}
.everybody > div#optionWrap {
  font-size: 0.88888889em;
}
.everybody > div#optionWrap h2 {
  font-size: 1.125em;
  background-color: #97c543;
  padding: 0.5em 1em;
  width: 100%;
  text-align: center;
  text-transform: uppercase;
}
.everybody > div#optionWrap h2 img {
  position: absolute;
  left: 1em;
  top: -25px;
}
.everybody > div#optionWrap p,
.everybody > div#optionWrap a {
  font-size: 1.125em;
  color: #4a4a4a;
  margin: 0;
}
.everybody > div#optionWrap .option {
  max-width: 570px;
  align-items: center;
}
.everybody > div#optionWrap .option:first-child {
  border-right: none;
}
.everybody > div#optionWrap .option > div {
  margin-top: 3em;
}
.everybody > div#optionWrap .option > div > img {
  margin: 0 2em 0 0;
  width: 190px;
  height: auto;
}
.everybody > div#optionWrap .option > div > div {
  border-left: solid 1px #97c543;
  padding-left: 2em;
  align-items: flex-start;
}
.everybody > div#optionWrap .option > div > div img {
  width: auto;
  max-height: 124px;
  height: 100%;
}
.everybody > div#optionWrap .option > div > div ul {
  margin: 1em 0 1em;
  padding-left: 1.25em;
}
.everybody > div#optionWrap .option > div > div ul > li {
  color: #4a4a4a;
}
.everybody > div#optionWrap .option > div > div a.button {
  max-width: 16.75em;
  width: 100%;
}
.noscroll {
  overflow: hidden;
}
@media (max-width: 1380px) {
  #overlay > div,
  #overlay > h1,
  #overlay > h2 {
    margin-left: 1em;
    margin-right: 1em;
  }
  #overlay h1 {
    font-size: 6em;
  }
  #overlay #optionWrap {
    flex-wrap: wrap;
  }
  #overlay #optionWrap .option {
    align-items: center;
  }
  #overlay #optionWrap .option:first-child {
    border-right: none;
  }
  .everybody > div#optionWrap {
    flex-direction: column;
    align-items: center;
  }
  .everybody > div#optionWrap .option {
    padding: 0 1em;
    max-width: 670px;
  }
}
@media (max-width: 600px) {
  #overlay > h2 {
    font-weight: 300;
  }
  #overlay h1 {
    font-size: 4em;
    padding-top: 0.5em;
    line-height: 1em;
    margin-bottom: 0.25em;
  }
  #overlay p {
    text-align: center;
  }
  #overlay #optionWrap {
    margin-top: 3em;
  }
  #overlay #optionWrap .option {
    padding: 1em !important;
  }
  #overlay #optionWrap .option > div {
    flex-wrap: wrap;
    justify-content: center;
  }
  #overlay #optionWrap .option > div > img {
    height: auto;
    max-width: 260px;
    width: 50%;
    padding: 0 1em;
    margin: 0;
  }
  #overlay #optionWrap .option > div > div {
    align-items: center;
  }
  #overlay #optionWrap .option > div > div img {
    height: auto;
    max-width: 200px;
    width: 100%;
    margin-top: 2em;
  }
  #overlay #optionWrap .option > div > div ul {
    margin-bottom: 2em;
  }
  .everybody > div#optionWrap p {
    margin: 0;
    max-width: 21em;
  }
  .everybody > div#optionWrap a {
    align-self: center;
  }
  .everybody > div#optionWrap h2 img {
    left: 0.5em;
    top: -12px;
    width: 45px;
  }
  .everybody > div#optionWrap .option > div {
    flex-direction: column;
    align-items: center;
  }
  .everybody > div#optionWrap .option > div > img {
    margin: 0 0 2em 0;
  }
  .everybody > div#optionWrap .option > div > div {
    border-left: none;
    padding-left: 0;
  }
}
@media (min-width: 375px) {
  section.hero {
    height: 110vw;
  }
}
@media (min-width: 410px) {
  section.hero {
    height: 100vw;
  }
}
@media (min-width: 480px) {
  section.hero {
    height: 95vw;
  }
  section.contact .multi-column {
    flex-direction: row;
    flex-wrap: wrap;
  }
  section.contact .video {
    margin: 3em 0.5em 1em;
    flex-basis: 10em;
  }
}
@media (min-width: 600px) {
  section.hero {
    height: 78vw;
    background-size: 150vw auto;
  }
}
@media (min-width: 720px) {
  html {
    font-size: 16px;
  }
  h1 {
    font-size: 3.55555em;
  }
  h1:after {
    top: 1.6em;
  }
  h2 {
    font-size: 2.66667em;
  }
  .sub-title {
    font-size: 1.6667em;
  }
  .badge-list {
    flex-direction: row;
  }
  .badge-list li {
    font-size: 0.7777em;
    line-height: 1.142857em;
    background-position: center 1em;
    max-width: 12em;
    padding-top: 12.5em;
    padding-left: 0;
    background-size: auto 12em;
    text-align: center;
  }
  .badge-list li.book {
    padding-top: 0;
    margin-bottom: 0;
    background-position: center 1em;
    height: auto;
  }
  .badge-list li.book a,
  .badge-list li.book a:link,
  .badge-list li.book a:visited {
    padding-top: 14em;
  }
  header {
    position: fixed;
    width: 100%;
    font-size: 0.66667em;
    height: 3.5em;
    overflow: visible;
    border-top: solid 2px white;
    display: flex;
    justify-content: space-between;
    align-content: center;
    z-index: 2;
  }
  header img {
    width: auto;
    height: 7em;
    margin-top: 1em;
    margin-left: 2em;
  }
  header nav {
    display: flex;
    align-items: center;
    margin: auto;
    margin-right: 0;
  }
  header nav ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }
  header nav ul li {
    display: inline-block;
  }
  header nav ul li a,
  header nav ul li a:link,
  header nav ul li a:visited,
  header nav ul li a:active {
    color: white;
    text-decoration: none;
    padding: 0 0.7em;
    margin: 0.7em 0;
    min-height: 4em;
    border-right: solid white 1px;
  }
  header nav ul li a:hover,
  header nav ul li a:link:hover,
  header nav ul li a:visited:hover,
  header nav ul li a:active:hover {
    text-decoration: underline;
  }
  header nav ul li:last-child a {
    border-width: 0;
  }
  header .get-started {
    margin-right: 2em;
    margin-left: 1em;
    position: relative;
    width: auto;
    bottom: auto;
    left: auto;
  }
  header .get-started.active ul {
    top: 0;
  }
  header .get-started h2 {
    font-size: 1.25em;
    cursor: auto;
  }
  header .get-started ul {
    display: block;
    top: 0;
  }
  header .get-started ul li:first-child a,
  header .get-started ul li:first-child a:link,
  header .get-started ul li:first-child a:visited {
    border-width: 0;
    padding-right: inherit;
    margin-right: inherit;
  }
  header .get-started ul li a,
  header .get-started ul li a:link,
  header .get-started ul li a:visited {
    padding-left: 4em;
    height: 4em;
  }
  .tabs {
    display: grid;
    grid-template-columns: 3fr 7fr;
  }
  .tabs nav {
    display: block;
  }
  .tabs nav ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    border-top: solid #97C543 1px;
  }
  .tabs nav ul li a {
    display: block;
    line-height: 3.33333em;
    border-bottom: solid #97C543 1px;
    text-align: right;
    padding-right: 1em;
    color: #4a4a4a;
    text-decoration: none;
    position: relative;
    cursor: pointer;
    transition: background-color 0.5s, color 0.5s;
  }
  .tabs nav ul li a:after {
    opacity: 0;
    transition: opacity 0.5s;
    content: "";
    background-size: contain;
    background-repeat: no-repeat;
    width: 0.55555em;
    height: 1.7222222em;
    position: absolute;
    right: -8px;
    top: 50%;
    margin-top: -0.8611111em;
  }
  .tabs nav ul li a:hover {
    background-color: rgba(151, 197, 67, 0.5);
  }
  .tabs nav ul li a.active {
    background-color: #97C543;
    color: white;
  }
  .tabs nav ul li a.active:after {
    background-image: url("../img/right-edge-arrow.svg");
    opacity: 1;
  }
  .tabs .tab-container {
    height: 26em;
  }
  .tabs .tab-container .tab-accordion-header {
    display: none;
  }
  .tabs .tab-container .tab {
    padding-left: 2em;
    width: 50%;
    position: absolute;
    top: 0;
    left: 0;
    height: 26em;
  }
  .tabs .tab-container .tab:after {
    max-height: none;
    bottom: 13.7vw;
    right: -29.4vw;
    width: 30vw;
    height: 37vw;
  }
  .tabs .tab-container .tab h3 {
    display: block;
    font-size: 1em;
    font-weight: 900;
    margin-top: 0;
  }
  .tabs .tab-container .tab .the-results {
    text-align: left;
    padding-top: 1.5em;
    margin-top: 2em;
  }
  .tabs .tab-container .tab .the-results .detail {
    max-width: 20em;
  }
  section {
    padding-bottom: 4em;
  }
  section.hero {
    background-size: 120vw auto;
    height: 82vw;
    min-height: 25em;
  }
  section.hero h1 {
    padding-top: 2.25em;
  }
  section.hero .sub-title {
    font-size: 2em;
  }
  section.hero p {
    font-size: 1.1111em;
    max-width: 38em;
  }
  section > p {
    max-width: 70vw;
  }
  section.contact form {
    grid-template-columns: 1fr 1fr;
    width: 70vw;
  }
  section.support {
    padding: 0 15vw 4em;
  }
  footer {
    flex-direction: row;
    justify-content: space-between;
    padding: 2em;
  }
  footer nav {
    width: initial;
    padding: 0;
    margin: 0;
    border-bottom: none;
  }
  footer > div {
    flex-direction: row;
    margin-top: 0;
  }
  footer > div img {
    margin: 0 2em;
  }
  footer > div > div {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    border-right: solid 1px #97c543;
    padding-right: 2em;
  }
  footer > div > div p {
    text-align: right;
  }
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s;
  }
  .fade-enter-active:after,
  .fade-leave-active:after {
    opacity: 1;
    transition: opacity 0.5s;
  }
  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }
  .fade-enter:after,
  .fade-leave-to:after {
    opacity: 0;
  }
}
@media (min-width: 900px) {
  html {
    font-size: 18px;
  }
  .badge-list {
    width: 80vw;
  }
  .tabs .tab-container .tab:after {
    bottom: -3.3vw;
    right: -26.4vw;
    width: 26vw;
    height: 32vw;
  }
  section.results:after {
    content: "";
    background-image: url("../img/pencil-coffee.png");
    background-size: cover;
    background-repeat: no-repeat;
    height: 35vw;
    width: 35vw;
    position: absolute;
    right: 0;
    bottom: -4vw;
  }
}
@media (min-width: 1000px) {
  cite {
    margin: 1.5em auto 0;
  }
  .badge-list {
    width: 70vw;
  }
  header img {
    height: 8em;
  }
  section.hero {
    height: 74vw;
  }
  section.contact .multi-column {
    flex-wrap: nowrap;
  }
}
@media (min-width: 1000px) {
  .tabs .tab-container .tab .the-results {
    text-align: center;
  }
  .tabs .tab-container .tab .the-results .detail {
    margin: 0.5em auto 0;
  }
  section.hero {
    height: 66vw;
    background-size: contain;
  }
}
@media (min-width: 1400px) {
  .tabs {
    margin-left: 12vw;
  }
  .tabs .tab-container .tab:after {
    right: -22.4vw;
  }
  section.hero {
    background-size: cover;
  }
  section.contact form {
    max-width: 50em;
  }
  section.support {
    padding-right: 20vw;
    padding-left: 20vw;
  }
}
@media (min-width: 1800px) {
  .tabs {
    margin-left: 20vw;
  }
  .tabs .tab-container .tab:after {
    right: -21.4vw;
    width: 23vw;
    height: 29vw;
  }
  section.hero {
    height: 55vw;
  }
  section.support {
    padding-right: 25vw;
    padding-left: 25vw;
  }
  section.results:after {
    height: 30vw;
    width: 30vw;
  }
}
@media (min-width: 2200px) {
  .badge-list {
    width: 60vw;
  }
  .tabs {
    margin-left: 20vw;
  }
  .tabs .tab-container .tab:after {
    right: -22.4vw;
    width: 19vw;
    height: 24vw;
  }
  section.hero {
    height: 46vw;
  }
  section.support {
    padding-right: 30vw;
    padding-left: 30vw;
  }
  section.results:after {
    height: 25vw;
    width: 25vw;
  }
}
/*# sourceMappingURL=styles.css.map */